import React from 'react';
import { DisplayField } from 'signer-app/signature-request';
import { useSignerTextInput } from 'signer-app/signer-signature-document/text-field-hooks';
import { DEFAULT_FONT_SIZE } from 'signer-app/signature-request/constants';
import { TextField } from 'signer-app/types/editor-types';
import { FieldUpdate } from 'signer-app/signer-signature-document/types';

export function isMultilineField(field: TextField) {
  return field.height >= (field.fontSize || DEFAULT_FONT_SIZE) * 2;
}

type Props = {
  fieldData: TextField;
  isActive: boolean;
  updateField: (updates: FieldUpdate) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onTouchEnd?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  getInstantValidationErrorHack: (id: string) => void;
  isOverlay: boolean;
  documentPreview: boolean;
};

type RefWithTextRef = {
  textRef: React.RefObject<HTMLTextAreaElement>;
};

function TextFieldComponent(
  {
    updateField,
    onChange,
    fieldData,
    getInstantValidationErrorHack,
    isOverlay,
    documentPreview,
    ...props
  }: Props,
  r: React.ForwardedRef<RefWithTextRef>,
) {
  const hidden = React.useRef<RefWithTextRef>();
  const ref = r as React.MutableRefObject<RefWithTextRef>;

  let style: React.CSSProperties = {
    whiteSpace: isMultilineField(fieldData) ? 'pre-wrap' : 'pre',
    wordBreak: 'break-word',
    wordSpacing: '0.05em !important',
    letterSpacing: '0.05em',
  };

  let locationName: 'review' | 'preview' | 'signer' | 'overlay' = 'signer';
  if (isOverlay) {
    locationName = 'overlay';
  } else if (documentPreview) {
    locationName = 'preview';
  }

  useSignerTextInput(
    fieldData,
    ref.current ? ref.current.textRef : undefined,
    hidden.current ? hidden.current.textRef : undefined,
    updateField,
    locationName,
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!isMultilineField(fieldData) && e.target.value.indexOf('\n') >= 0) {
      return;
    }

    // Prevent accepting spaces with no other text
    if (e.target.value.trim().length === 0) {
      e.target.value = '';
    }
    onChange(e);
  };

  // HACK, REMOVE with split. We compare the hidden element's scrollHeight to the visible
  // element's clientHeight but without this on Mac screens .scrollHeight appears off
  // by 1px and lowering line-height lets us text wrap on text input fields. So far this
  // is only reproducible when fieldData.height is 14px and fontSize is 7px
  style = {
    ...style,
    lineHeight: 0.9999,
  };

  const value = fieldData.value || '';

  if (isOverlay && value === '') {
    return null;
  }

  return (
    <React.Fragment>
      <DisplayField
        {...props}
        placeholder={fieldData.placeholder || ''}
        fieldData={
          !props.isActive && fieldData.masked
            ? {
                ...fieldData,
                value: value.replace(/./gi, '*'),
              }
            : fieldData
        }
        // @ts-expect-error I know this code works, but the types aren't lining
        // up on the refs
        ref={ref}
        style={{
          ...style,
        }}
        onKeyDown={undefined}
        onChange={(e: React.ChangeEvent<Element>) =>
          handleChange(e as React.ChangeEvent<HTMLTextAreaElement>)
        }
      />
      <div
        style={{
          position: 'absolute',
          top: '100%',
          height: '100%',
          width: '100%',
          visibility: 'hidden',
        }}
      >
        <DisplayField
          {...props}
          fieldData={fieldData}
          style={{
            // The whole pemise of this componet is tha it renders exactly the
            // same componnt with one visible and one invisible.
            ...style,
            // minHeight and height need to be reset, because the amount of
            // space the text can occupy is measured by looking at the
            // `.scrollHeight`.
            minHeight: 0,
            height: 0,
            // Please do not make intentional differences between the on-screen
            // and off-screen components. Any difference causes the measurements
            // to be invalid.
          }}
          placeholder={
            // do not apply placeholders here. It forces the field to be at
            // least the size of the placeholder, so it's not able to shrink
            // properly.
            ''
          }
          data-qa-ref={undefined}
          // @ts-expect-error I know this code works, but the types aren't
          // lining up on the refs
          ref={hidden}
        />
      </div>
    </React.Fragment>
  );
}

export default React.forwardRef(TextFieldComponent);
