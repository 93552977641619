import styles from 'signer-app/signature-modal/upload/signature-edit-area.module.css';

import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Text } from '@dropbox/dig-components/typography';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import {
  RotateRightLine,
  DeleteLine,
  ZoomOutLine,
  ZoomInLine,
} from '@dropbox/dig-icons/assets';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import CONSTANTS from 'signer-app/signature-modal/constants';

import Croppable, { Bounds } from 'signer-app/signature-modal/upload/croppable';
import { ButtonsDivider } from 'signer-app/signature-modal/common/buttons-divider';
import { SignatureEditAreaHelper } from 'signer-app/signature-modal/helpers/signature-edit-area-helper';
import {
  Dimensions,
  scaleSignatureToFit,
} from 'signer-app/signature-modal/utils/scale-signature-to-fit';
import { Signature } from 'signer-app/signature-modal/types';

const messages = defineMessages({
  signaturePreview: {
    id: '',
    description:
      'Description of image presenting signature preview in edit mode',
    defaultMessage: 'Signature preview',
  },
  increaseContrast: {
    id: '',
    description:
      'Screen reader label for a button that increases contrast of the uploaded signature image.',
    defaultMessage:
      'Increase contrast, current contrast {contrastPercentage, number, percent}',
  },
  decreaseContrast: {
    id: '',
    description:
      'Screen reader label for a button that decreases contrast of the uploaded signature image.',
    defaultMessage:
      'Decrease contrast, current contrast {contrastPercentage, number, percent}',
  },
  rotateAriaLabel: {
    id: '',
    description:
      'Screen reader label for a button which rotates an image clockwise when pressed, containing information how many degrees it is currently rotated.',
    defaultMessage: 'Rotate, signature rotated {rotation, number} degrees',
  },
  deleteAriaLabel: {
    id: '',
    description:
      'Screen reader label for a button which deletes am image when pressed.',
    defaultMessage: 'Delete signature',
  },
});

export interface SignatureEditAreaProps {
  signature: Signature;
  getSignatureUrl: (
    signature: Signature,
    contrast: number,
    rotation: number,
  ) => string;
  clearUploadedSignature: () => void;
  contrast: number;
  rotation: number;
  isMobile: boolean;
  onPreviewChange: (
    data: Partial<{
      bounds: Bounds | null;
      contrast: number;
      rotation: number;
    }>,
  ) => void;
}

const PREVIEW_AREA_DIMENSIONS: Dimensions = {
  width: 398,
  height: 120,
};

const SignatureEditArea: FC<SignatureEditAreaProps> = ({
  contrast,
  rotation,
  signature,
  getSignatureUrl,
  clearUploadedSignature,
  onPreviewChange,
  isMobile,
}) => {
  const intl = useIntl();
  const cache = useRef(new Map<string, HTMLImageElement>()).current;
  const increaseContrastRef = useRef<HTMLElement>(null);
  const decreaseContrastRef = useRef<HTMLElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const contrastPercentage =
    (contrast - CONSTANTS.CONTRAST_MIN_THRESHOLD) /
    (CONSTANTS.CONTRAST_MAX_THRESHOLD - CONSTANTS.CONTRAST_MIN_THRESHOLD);

  useEffect(() => {
    // initially focus on the decrease contrast button
    decreaseContrastRef.current?.focus();
  }, []);

  useEffect(() => {
    if (contrast <= CONSTANTS.CONTRAST_MIN_THRESHOLD) {
      // focus on increase button if contrast is already min
      increaseContrastRef.current?.focus();
    } else if (contrast >= CONSTANTS.CONTRAST_MAX_THRESHOLD) {
      // move focus to the decrease contrast button if max contrast reached
      decreaseContrastRef.current?.focus();
    }
  }, [contrast]);

  const previewStyle = useMemo(() => {
    if (!signature) return {};

    const url = getSignatureUrl(signature, contrast, rotation);
    const backgroundImage = `url(${url})`;
    const backgroundSize = '100%';

    const width = parseInt(String(signature.width), 10);
    const height = parseInt(String(signature.height), 10);

    const imageDims: Dimensions =
      rotation === 90 || rotation === 270
        ? {
            width: height,
            height: width,
          }
        : {
            width,
            height,
          };

    return {
      ...scaleSignatureToFit(imageDims, PREVIEW_AREA_DIMENSIONS),
      backgroundImage,
      backgroundSize,
    };
  }, [signature, getSignatureUrl, contrast, rotation]);

  useEffect(() => {
    if (signature) {
      for (
        let tempContrast = CONSTANTS.CONTRAST_MIN_THRESHOLD;
        tempContrast <= CONSTANTS.CONTRAST_MAX_THRESHOLD;
        tempContrast += CONSTANTS.CONTRAST_STEP_AMOUNT
      ) {
        const cacheKey = `c${tempContrast}d${rotation}`;
        const image = new Image();
        image.src = getSignatureUrl(signature, tempContrast, rotation);
        cache.set(cacheKey, image);
      }
    }
  }, [signature, rotation, cache, getSignatureUrl]);

  const handleRotate = useCallback(() => {
    const increasedRotation = rotation + CONSTANTS.ROTATE_DEGREES_AMOUNT;
    onPreviewChange({
      rotation: increasedRotation >= 360 ? 0 : increasedRotation,
    });
  }, [onPreviewChange, rotation]);

  const handleDelete = useCallback(() => {
    clearUploadedSignature();
  }, [clearUploadedSignature]);

  const decreaseContrast = useCallback(() => {
    if (contrast > CONSTANTS.CONTRAST_MIN_THRESHOLD) {
      onPreviewChange({
        contrast: contrast - CONSTANTS.CONTRAST_STEP_AMOUNT,
      });
    }
  }, [contrast, onPreviewChange]);

  const increaseContrast = useCallback(() => {
    if (contrast < CONSTANTS.CONTRAST_MAX_THRESHOLD) {
      onPreviewChange({
        contrast: contrast + CONSTANTS.CONTRAST_STEP_AMOUNT,
      });
    }
  }, [contrast, onPreviewChange]);

  const handleSelect = useCallback(
    ({ left, top, width, height }: Bounds) => {
      const preview = previewRef.current;
      if (preview) {
        const previewBounds = preview.getBoundingClientRect();

        const preScaledCrop = {
          x: left - previewBounds.left,
          y: top - previewBounds.top,
          width,
          height,
        };

        if (preScaledCrop.width > 0 && preScaledCrop.height > 0) {
          const scaledParams = SignatureEditAreaHelper.scaleCrop(
            previewBounds,
            {
              width: String(signature.width),
              height: String(signature.height),
            },
            preScaledCrop,
            rotation,
          );

          onPreviewChange({
            bounds: {
              left: scaledParams.x,
              top: scaledParams.y,
              width: scaledParams.width,
              height: scaledParams.height,
            },
          });
        } else {
          onPreviewChange({
            bounds: null,
          });
        }
      }
    },
    [signature, rotation, onPreviewChange],
  );

  return (
    <div className={styles.editArea}>
      <Croppable onSelect={handleSelect}>
        <div
          role="img"
          aria-label={intl.formatMessage(messages.signaturePreview)}
          data-qa-ref={'signing-modal--preview-image'}
          className={styles.previewImage}
          style={previewStyle}
          ref={previewRef}
        />
      </Croppable>
      <div className={styles.buttons}>
        <Text
          id="signature-contrast-label"
          className={styles.contrastLabel}
          size="small"
          isBold
        >
          <FormattedMessage
            id=""
            description="Text label in signer modal, where signer can adjust contrast of typed/drawn signature/initials"
            defaultMessage="Contrast"
          />
        </Text>
        <IconButton
          ref={decreaseContrastRef}
          variant="borderless"
          className={styles.decreaseContrast}
          aria-label={intl.formatMessage(messages.decreaseContrast, {
            contrastPercentage,
          })}
          disabled={contrast <= CONSTANTS.CONTRAST_MIN_THRESHOLD}
          onClick={decreaseContrast}
        >
          <UIIcon src={ZoomOutLine} />
        </IconButton>
        <Text
          aria-labelledby="signature-contrast-label"
          className={styles.contrastValue}
          size="small"
          isBold
        >
          {intl.formatNumber(contrastPercentage, { style: 'percent' })}
        </Text>
        <IconButton
          ref={increaseContrastRef}
          variant="borderless"
          aria-label={intl.formatMessage(messages.increaseContrast, {
            contrastPercentage,
          })}
          disabled={contrast >= CONSTANTS.CONTRAST_MAX_THRESHOLD}
          onClick={increaseContrast}
        >
          <UIIcon src={ZoomInLine} />
        </IconButton>
        {isMobile ? (
          <>
            <ButtonsDivider />
            <IconButton
              variant="borderless"
              aria-label={intl.formatMessage(messages.rotateAriaLabel, {
                rotation,
              })}
              onClick={handleRotate}
            >
              <UIIcon src={RotateRightLine} />
            </IconButton>
            <ButtonsDivider />
            <IconButton
              variant="borderless"
              aria-label={intl.formatMessage(messages.deleteAriaLabel)}
              onClick={handleDelete}
            >
              <UIIcon src={DeleteLine} />
            </IconButton>
          </>
        ) : (
          <>
            <ButtonsDivider />
            <Button
              size="small"
              variant="borderless"
              className={styles.textButton}
              aria-label={intl.formatMessage(messages.rotateAriaLabel, {
                rotation,
              })}
              withIconLeft={<UIIcon src={RotateRightLine} />}
              onClick={handleRotate}
            >
              <FormattedMessage
                id=""
                description="Text for a button which rotates an image clockwise when pressed."
                defaultMessage="Rotate"
              />
            </Button>
            <ButtonsDivider />
            <Button
              size="small"
              variant="borderless"
              className={styles.textButton}
              aria-label={intl.formatMessage(messages.deleteAriaLabel)}
              withIconLeft={<UIIcon src={DeleteLine} />}
              onClick={handleDelete}
            >
              <FormattedMessage
                id=""
                description="Text for a button which deletes an image when pressed."
                defaultMessage="Delete"
              />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default SignatureEditArea;
