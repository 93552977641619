import { defineMessages } from 'react-intl';

export type codeDescription = {
  id: string;
  defaultMessage: string;
  description: string;
};

export type codeMap = {
  [key: string]: codeDescription;
};

export const errorCodes: codeMap = defineMessages({
  code400: {
    id: '',
    defaultMessage: 'Bad Request',
    description: 'The request cannot be fulfilled due to bad syntax.',
  },
  code401: {
    id: '',
    defaultMessage: 'Unauthorized',
    description:
      'The request was a legal request, but the server is refusing to respond to it. For use when authentication is possible but has failed or not yet been provided.',
  },
  code402: {
    id: '',
    defaultMessage: 'Payment Required',
    description:
      'Not yet implemented by RFC standards, but reserved for future use.',
  },
  code403: {
    id: '',
    defaultMessage: 'Forbidden',
    description:
      'The request was a legal request, but the server is refusing to respond to it.',
  },
  code404: {
    id: '',
    defaultMessage: 'Not Found',
    description:
      'The requested page could not be found but may be available again in the future.',
  },
  code405: {
    id: '',
    defaultMessage: 'Method Not Allowed',
    description:
      'A request was made of a page using a request method not supported by that page.',
  },
  code406: {
    id: '',
    defaultMessage: 'Not Acceptable',
    description:
      'The server can only generate a response that is not accepted by the client.',
  },
  code407: {
    id: '',
    defaultMessage: 'Proxy Authentication Required',
    description: 'The client must first authenticate itself with the proxy.',
  },
  code408: {
    id: '',
    defaultMessage: 'Request Timeout',
    description: 'The server timed out waiting for the request.',
  },
  code409: {
    id: '',
    defaultMessage: 'Conflict',
    description:
      'The request could not be completed because of a conflict in the request.',
  },
  code410: {
    id: '',
    defaultMessage: 'Gone',
    description: 'The requested page is no longer available.',
  },
  code411: {
    id: '',
    defaultMessage: 'Length Required',
    description:
      'The "Content-Length" is not defined. The server will not accept the request without it.',
  },
  code412: {
    id: '',
    defaultMessage: 'Precondition Failed',
    description:
      'The precondition given in the request evaluated to false by the server.',
  },
  code413: {
    id: '',
    defaultMessage: 'Request Entity Too Large',
    description:
      'The server will not accept the request, because the request entity is too large.',
  },
  code414: {
    id: '',
    defaultMessage: 'Request-URI Too Long',
    description:
      'The server will not accept the request, because the URL is too long. Occurs when you convert a POST request to a GET request with a long query information.',
  },
  code415: {
    id: '',
    defaultMessage: 'Unsupported Media Type',
    description:
      'The server will not accept the request, because the media type is not supported.',
  },
  code416: {
    id: '',
    defaultMessage: 'Requested Range Not Satisfiable',
    description:
      'The client has asked for a portion of the file, but the server cannot supply that portion.',
  },
  code417: {
    id: '',
    defaultMessage: 'Expectation Failed',
    description:
      'The server cannot meet the requirements of the Expect request-header field.',
  },
  code418: {
    id: '',
    defaultMessage: "I'm a teapot",
    description:
      'Any attempt to brew coffee with a teapot should result in the error code "418 I\'m a teapot". The resulting entity body MAY be short and stout.',
  },
  code419: {
    id: '',
    defaultMessage: 'Page Expired (Laravel Framework)',
    description:
      'Used by the Laravel Framework when a CSRF Token is missing or expired.',
  },
  code420: {
    id: '',
    defaultMessage: 'Method Failure (Spring Framework)',
    description:
      'A deprecated response used by the Spring Framework when a method has failed.',
  },
  code421: {
    id: '',
    defaultMessage: 'Misdirected Request',
    description:
      'The request was directed at a server that is not able to produce a response (for example because a connection reuse).',
  },
  code422: {
    id: '',
    defaultMessage: 'Unprocessable Entity',
    description:
      'The request was well-formed but was unable to be followed due to semantic errors.',
  },
  code423: {
    id: '',
    defaultMessage: 'Locked',
    description: 'The resource that is being accessed is locked.',
  },
  code424: {
    id: '',
    defaultMessage: 'Failed Dependency',
    description:
      'The request failed due to failure of a previous request (e.g., a PROPPATCH).',
  },
  code426: {
    id: '',
    defaultMessage: 'Upgrade Required',
    description:
      'The client should switch to a different protocol such as TLS/1.0, given in the Upgrade header field.',
  },
  code428: {
    id: '',
    defaultMessage: 'Precondition Required',
    description: 'The origin server requires the request to be conditional.',
  },
  code429: {
    id: '',
    defaultMessage: 'Too Many Requests',
    description:
      'The user has sent too many requests in a given amount of time. Intended for use with rate limiting schemes.',
  },
  code431: {
    id: '',
    defaultMessage: 'Request Header Fields Too Large',
    description:
      'The server is unwilling to process the request because either an individual header field, or all the header fields collectively, are too large.',
  },
  code440: {
    id: '',
    defaultMessage: 'Login Time-out',
    description:
      "The client's session has expired and must log in again. (IIS)",
  },
  code444: {
    id: '',
    defaultMessage: 'Connection Closed Without Response',
    description:
      'A non-standard status code used to instruct nginx to close the connection without sending a response to the client, most commonly used to deny malicious or malformed requests.',
  },
  code449: {
    id: '',
    defaultMessage: 'Retry With',
    description:
      'The server cannot honour the request because the user has not provided the required information. (IIS)',
  },
  code450: {
    id: '',
    defaultMessage: 'Blocked by Windows Parental Controls',
    description:
      'The Microsoft extension code indicated when Windows Parental Controls are turned on and are blocking access to the requested webpage.',
  },
  code451: {
    id: '',
    defaultMessage: 'Unavailable For Legal Reasons',
    description:
      'A server operator has received a legal demand to deny access to a resource or to a set of resources that includes the requested resource.',
  },
  code494: {
    id: '',
    defaultMessage: 'Request Header Too Large',
    description:
      'Used by nginx to indicate the client sent too large of a request or header line that is too long.',
  },
  code495: {
    id: '',
    defaultMessage: 'SSL Certificate Error',
    description:
      'An expansion of the 400 Bad Request response code, used when the client has provided an invalid client certificate.',
  },
  code496: {
    id: '',
    defaultMessage: 'SSL Certificate Required',
    description:
      'An expansion of the 400 Bad Request response code, used when a client certificate is required but not provided.',
  },
  code497: {
    id: '',
    defaultMessage: 'HTTP Request Sent to HTTPS Port',
    description:
      'An expansion of the 400 Bad Request response code, used when the client has made a HTTP request to a port listening for HTTPS requests.',
  },
  code498: {
    id: '',
    defaultMessage: 'Invalid Token (Esri)',
    description:
      'Returned by ArcGIS for Server. Code 498 indicates an expired or otherwise invalid token.',
  },
  code499: {
    id: '',
    defaultMessage: 'Client Closed Request',
    description:
      'A non-standard status code introduced by nginx for the case when a client closes the connection while nginx is processing the request.',
  },
  code500: {
    id: '',
    defaultMessage: 'Internal Server Error',
    description:
      'An error has occurred in a server side script, a no more specific message is suitable.',
  },
  code501: {
    id: '',
    defaultMessage: 'Not Implemented',
    description:
      'The server either does not recognize the request method, or it lacks the ability to fulfill the request.',
  },
  code502: {
    id: '',
    defaultMessage: 'Bad Gateway',
    description:
      'The server was acting as a gateway or proxy and received an invalid response from the upstream server.',
  },
  code503: {
    id: '',
    defaultMessage: 'Service Unavailable',
    description: 'The server is currently unavailable (overloaded or down).',
  },
  code504: {
    id: '',
    defaultMessage: 'Gateway Timeout',
    description:
      'The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.',
  },
  code505: {
    id: '',
    defaultMessage: 'HTTP Version Not Supported',
    description:
      'The server does not support the HTTP protocol version used in the request.',
  },
  code506: {
    id: '',
    defaultMessage: 'Variant Also Negotiates',
    description:
      'Transparent content negotiation for the request results in a circular reference.',
  },
  code507: {
    id: '',
    defaultMessage: 'Insufficient Storage',
    description:
      'The server is unable to store the representation needed to complete the request.',
  },
  code508: {
    id: '',
    defaultMessage: 'Loop Detected',
    description:
      'The server detected an infinite loop while processing the request (sent instead of 208 Already Reported).',
  },
  code509: {
    id: '',
    defaultMessage: 'Bandwidth Limit Exceeded',
    description:
      'The server has exceeded the bandwidth specified by the server administrator; this is often used by shared hosting providers to limit the bandwidth of customers.',
  },
  code510: {
    id: '',
    defaultMessage: 'Not Extended',
    description:
      'Further extensions to the request are required for the server to fulfil it.',
  },
  code511: {
    id: '',
    defaultMessage: 'Network Authentication Required',
    description: 'The client needs to authenticate to gain network access.',
  },
  code520: {
    id: '',
    defaultMessage: 'Unknown Error',
    description:
      'The 520 error is used as a "catch-all response for when the origin server returns something unexpected", listing connection resets, large headers, and empty or invalid responses as common triggers.',
  },
  code521: {
    id: '',
    defaultMessage: 'Web Server Is Down',
    description:
      'The origin server has refused the connection from Cloudflare.',
  },
  code522: {
    id: '',
    defaultMessage: 'Connection Timed Out',
    description:
      'Cloudflare could not negotiate a TCP handshake with the origin server.',
  },
  code523: {
    id: '',
    defaultMessage: 'Origin Is Unreachable',
    description:
      'Cloudflare could not reach the origin server; for example, if the DNS records for the origin server are incorrect.',
  },
  code524: {
    id: '',
    defaultMessage: 'A Timeout Occurred',
    description:
      'Cloudflare was able to complete a TCP connection to the origin server, but did not receive a timely HTTP response.',
  },
  code525: {
    id: '',
    defaultMessage: 'SSL Handshake Failed',
    description:
      'Cloudflare could not negotiate a SSL/TLS handshake with the origin server.',
  },
  code526: {
    id: '',
    defaultMessage: 'Invalid SSL Certificate',
    description:
      "Used by Cloudflare and Cloud Foundry's gorouter to indicate failure to validate the SSL/TLS certificate that the origin server presented.",
  },
  code527: {
    id: '',
    defaultMessage: 'Railgun Listener to Origin Error',
    description:
      'Error 527 indicates that the request timed out or failed after the WAN connection had been established.',
  },
  code530: {
    id: '',
    defaultMessage: 'Origin DNS Error',
    description:
      'Error 530 indicates that the requested host name could not be resolved on the Cloudflare network to an origin server.',
  },
  code598: {
    id: '',
    defaultMessage: 'Network Read Timeout Error',
    description:
      'Used by some HTTP proxies to signal a network read timeout behind the proxy to a client in front of the proxy.',
  },
});
