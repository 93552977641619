import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Field } from 'signer-app/types/editor-types';
import { Tooltip } from '@dropbox/dig-components/tooltips';
import { Button } from '@dropbox/dig-components/buttons';
import { OverlayPlacement } from '@dropbox/dig-components/overlay';
import { SENDER } from 'signer-app/signature-request/constants';
import { Text, Title } from '@dropbox/dig-components/typography';
import styles from 'signer-app/signature-request/field-tooltip.module.css';

const refMap = new Map<string, React.MutableRefObject<any>>();

type Props = {
  activeFields: string[];
  fields: Field[];
  isOpen?: boolean;
  isPortaled?: boolean;
  placement?: OverlayPlacement;
};
export default function FieldTooltip({
  activeFields,
  fields,
  isOpen,
  isPortaled,
  placement,
}: Props) {
  const [hasShown, setHasShown] = React.useState(false);

  /**
   *  A user should only see a tooltip once on the first field they interact with.
   *  Once a user clears that tooltip, it should never be shown again.
   * */

  const fieldTarget = React.useMemo(
    () => fields.find((f) => f.id === activeFields[0]),
    [activeFields, fields],
  );

  const isMergeField = fieldTarget?.signer === SENDER;

  React.useEffect(() => {
    return () => {
      if (isMergeField && !hasShown) {
        setHasShown(true);
      }
    };
  }, [isMergeField, hasShown]);

  const ref = refMap.get(fieldTarget?.id ?? '');

  if (!fieldTarget || !isMergeField) {
    return null;
  }

  if (!ref || hasShown) {
    return null;
  }

  return (
    <Tooltip.Control
      className={styles.container}
      variant="rich"
      placement={placement || 'bottom'}
      open={!!isOpen}
      triggerRef={ref}
      maxWidth={512}
      // isPortaled will be added to Control's type via DSYS-2570
      // @ts-ignore
      isPortaled={isPortaled}
    >
      <Title size="medium" inverse>
        <FormattedMessage
          id=""
          description="Title for a tooltip when a user interacts with a field."
          defaultMessage="Preview mode"
        />
      </Title>
      <Text tagName="p" color="standard" inverse>
        <FormattedMessage
          id=""
          description="Helper text for a tooltip when a user interacts with a field."
          defaultMessage="Info entered in this preview doesn't get saved."
        />
      </Text>
      <div className={styles.footer}>
        <Button
          type="button"
          variant="outline"
          inverse
          onClick={() => setHasShown(true)}
        >
          <FormattedMessage
            id=""
            description="Text for a button which closes a tooltip when pressed."
            defaultMessage="Got it"
          />
        </Button>
      </div>
    </Tooltip.Control>
  );
}

export function useSetFieldTooltipRef(
  id: string,
  ref: React.MutableRefObject<any>,
) {
  React.useEffect(() => {
    refMap.set(id, ref);
    return () => {
      refMap.delete(id);
    };
  }, [id, ref]);
}
