import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import styles from 'signer-app/parts/loader-spinner/loader-spinner.module.css';
import loadingSpinner from 'signer-app/parts/loader-spinner/assets/loading-spinner.gif';

const messages = defineMessages({
  loading: {
    id: '',
    description: 'Loading message.',
    defaultMessage: 'Loading…',
  },
});

export default function LoaderSpinner() {
  const intl = useIntl();

  return (
    <div>
      <img
        alt={intl.formatMessage(messages.loading)}
        src={loadingSpinner}
        className={styles.LoaderSpinner}
      />
    </div>
  );
}
