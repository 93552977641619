import React from 'react';
import { GlobalHeader } from '@dropbox/dig-components/global_header';
import { FssWordmark } from '@dropbox/dig-logos';
import { NextButton } from 'signer-app/signer-experience/next-button';
import { FormattedMessage } from 'react-intl';
import { Button } from '@dropbox/dig-components/buttons';
import { LegalConfirm } from 'signer-app/signer-experience/legal-confirm';

export function ReviewToolbar(props: { legalVersion: number }) {
  return (
    <GlobalHeader.Wrapper>
      <GlobalHeader.Logo src={FssWordmark} href="/" />

      <div>
        <FormattedMessage
          id="signer.confirm.title"
          description="text in signer flow, informing signer the process of signing the document is almost done"
          defaultMessage="Almost done."
        />

        <LegalConfirm legalVersion={props.legalVersion} />
      </div>

      <GlobalHeader.UtilityNav>
        <Button variant="borderless">
          <FormattedMessage
            defaultMessage="Edit"
            description="Button to edit the document."
          />
        </Button>
        <NextButton>
          <FormattedMessage
            defaultMessage="I agree"
            description="Button to agree to the document."
          />
        </NextButton>
      </GlobalHeader.UtilityNav>
    </GlobalHeader.Wrapper>
  );
}
