import { Template } from 'js/sign-components/generated/types/HelloRequest';
import { queryParams } from 'js/sign-components/common/fetch';
import hsFetch, { getCSRFToken } from 'js/sign-components/common/hs-fetch';
import type { TemplatePermissions } from 'hellospa/common/models/templates/template-permissions';

export type TemplateCriteria = {
  starred: boolean;
  search: string | null;
  includeTemplateLinks: boolean;
};
export async function fetchTemplates(
  criteria: TemplateCriteria,
  pageNum: number,
  pageSize: number = 20,
): Promise<{
  total: number;
  pageSize: number;
  data: Array<Template>;
}> {
  let filter = 'template';
  if (criteria.includeTemplateLinks) {
    filter = criteria.starred
      ? 'account_starred_template_or_link'
      : 'template_or_link';
  } else {
    filter = criteria.starred ? 'account_starred_template' : 'template';
  }
  const url = `/home/manageSearch${queryParams({
    filter,
    page_num: pageNum,
    page_size: pageSize,
    response_format: 'spa',
    q: criteria.search ? criteria.search : '',
  })}`;
  const response = await hsFetch(url, {
    credentials: 'same-origin',
    method: 'POST',
  });
  if (response.status !== 200) {
    throw new Error('Invalid response code');
  }

  const data = await response.json();

  return {
    total: data.numResults,
    pageSize: data.pageSize,
    data: data.pageResults,
  };
}

export async function fetchRecentTemplates(): Promise<{
  templates: Template[];
}> {
  const response = await hsFetch('/endpoint/account/recentlyUsedTemplates', {
    credentials: 'same-origin',
    method: 'POST',
  });

  if (response.status !== 200) {
    throw new Error(
      'Failed to fetch recently used templates, invalid response code',
    );
  }

  const data = await response.json();

  return {
    templates: data.data,
  };
}

export async function setStarred(_templateId: string, _starred: boolean) {
  const url = `/endpoint/template/star${queryParams({
    star: Number(_starred),
    guid: _templateId,
  })}`;

  const response = await hsFetch(url, {
    credentials: 'same-origin',
    method: 'PUT',
  });
  const data = await response.json();

  return data;
}

export async function renameTemplate(_templateId: string, _newName: string) {
  const url = '/home/renameTemplate';

  const body = new FormData();
  body.append('csrf_token', getCSRFToken());
  body.append('template_guid', _templateId);
  body.append('new_template_name', _newName);
  const response = await hsFetch(url, {
    method: 'POST',
    body,
  });
  const data = await response.json();

  return data;
}

export async function setReceiveSignedCopies(
  templateGuid: string,
  _receiveSignedCopes: boolean,
) {
  const url = '/home/toggleTemplateLinkCopies';

  const body = new FormData();
  body.append('csrf_token', getCSRFToken());
  body.append('guid', templateGuid);
  await hsFetch(url, {
    method: 'POST',
    body,
  });
}

export async function setInPersonSigning(
  templateGuid: string,
  inPersonSigning: boolean,
): Promise<{ success: boolean }> {
  const url = inPersonSigning
    ? '/home/activateTemplateOnSiteSigning'
    : '/home/disableTemplateOnSiteSigning';

  const body = new FormData();
  body.append('csrf_token', getCSRFToken());
  body.append('guid', templateGuid);
  const response = await hsFetch(url, {
    method: 'POST',
    body,
  });
  const data = await response.json();

  return {
    success: Boolean(data.success),
  };
}

export async function fetchTemplatePermissions(
  templateGuid: string,
): Promise<TemplatePermissions> {
  const response = await hsFetch(`/template/permissions?guid=${templateGuid}`);

  if (response.status !== 200) {
    throw new Error(`Invalid response code ${response.status}`);
  }

  return response.json();
}

export async function deleteTemplate(templateGuid: string) {
  const formData = new FormData();
  formData.append('csrf_token', getCSRFToken());
  formData.append('template_guid', templateGuid);

  const response = await hsFetch('/attachment/delete', {
    method: 'POST',
    body: formData,
  });

  const result = await response.json();
  if (result.error) {
    throw new Error(result.error);
  }
}

export async function acknowledgeTemplateEducation() {
  const formData = new FormData();
  formData.append('csrf_token', getCSRFToken());

  const response = await hsFetch('/home/acknowledgeTemplateEducation', {
    method: 'POST',
    body: formData,
  });

  const result = await response.json();
  if (result.error) {
    throw new Error(result.error);
  }
}
