import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import classnames from 'classnames';
import { SiteCodeType, getBrandName } from 'signer-app/utils/brand';
import { unreachable } from 'signer-app/utils/unreachable';

import {
  WordmarkLogo,
  GlyphLogo,
  FaxGlyph,
  SignGlyph,
  FormsGlyph,
  FaxWordmark,
  SignWordmark,
  SignAPIWordmark,
  FormsWordmark,
  FssWordmark,
  FssGlyph,
  WordmarkLogoSize,
} from '@dropbox/dig-logos';

import styles from 'signer-app/parts/logo-v2/styles.module.css';
import { getSite, urlHelper } from 'signer-app/utils/url-helpers';

const messages = defineMessages({
  goHome: {
    id: '',
    description:
      'Title text for a button which takes the user home when pressed.',
    defaultMessage: 'Go to {brandName} homepage',
  },
});

type LogoProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  siteCode: SiteCodeType;
  size?: WordmarkLogoSize;
  className?: string;
  href?: string;
  variant?: 'api' | 'wordmark' | 'glyph'; // default 'wordmark'
};

const Logo: React.FC<LogoProps> = ({
  siteCode,
  href,
  variant = 'wordmark',
  className = '',
  size = 32,
}) => {
  const intl = useIntl();
  const site = getSite(siteCode);

  const wordmarkSrc = React.useMemo(() => {
    switch (siteCode) {
      case 'S':
        return SignWordmark;
      case 'F':
        return FaxWordmark;
      case 'W':
        return FormsWordmark;
      case 'D':
        return FssWordmark;
      default:
        return unreachable(siteCode);
    }
  }, [siteCode]);

  const glyphSrc = React.useMemo(() => {
    switch (siteCode) {
      case 'S':
        return SignGlyph;
      case 'F':
        return FaxGlyph;
      case 'W':
        return FormsGlyph;
      case 'D':
        return FssGlyph;
      default:
        return unreachable(siteCode);
    }
  }, [siteCode]);

  return (
    <div
      className={classnames(styles.logo, className)}
      // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
      // eslint-disable-next-line no-restricted-syntax
      data-qa-ref="hellosign-app-logo-container"
    >
      <a
        className={styles.logoLink}
        href={href ?? urlHelper('home/index', site)}
        title={intl.formatMessage(messages.goHome, {
          brandName: getBrandName(siteCode),
        })}
        // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
        // eslint-disable-next-line no-restricted-syntax
        data-qa-ref="hellosign-app-logo"
      >
        {variant === 'api' && (
          <WordmarkLogo
            aria-hidden="true"
            size={size}
            className={styles.logo}
            src={SignAPIWordmark}
          />
        )}
        {wordmarkSrc && variant === 'wordmark' && (
          <WordmarkLogo
            aria-hidden="true"
            size={size}
            className={styles.logo}
            src={wordmarkSrc}
          />
        )}
        {glyphSrc && variant === 'glyph' && (
          <GlyphLogo
            aria-hidden="true"
            size={size}
            className={styles.logo}
            src={glyphSrc}
          />
        )}
      </a>
    </div>
  );
};

export default Logo;
