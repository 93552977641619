import hsFetch from 'signer-app/utils/hs-fetch';
import * as Sentry from '@sentry/browser';
import { mergeTourData } from 'signer-app/context/signer-app-client/tour-local-storage-utils';

export type TourName =
  | 'onboarding-global-nav-tour'
  | 'mobile-editor'
  | 'deep-integration-fields-panel'
  | 'signature-requests'
  | 'bulk-send-tooltip-tour'
  | 'onboarding-prep-and-send-1-select-documents'
  | 'onboarding-prep-and-send-2-add-signers'
  | 'onboarding-prep-and-send-3-place-fields'
  | 'forms-education-1-editor-label'
  | 'forms-education-2-editor-preview'
  | 'api-dashboard-nav-link'
  | 'editor-replace-documents'
  | 'document-expiration'
  | 'story-tour'; // this tour is used in story-book

export async function markTourAsCompletedOnBackend(tour: TourName) {
  const res = await hsFetch(`/endpoint/account/acknowledgeTour?tour=${tour}`, {
    method: 'POST',
  });
  const body = await res.json();
  if (res.status === 200) {
    return true;
  } else if (res.status === 501) {
    const msg = `Tour "${tour}" is not setup to work with the TourManager, see drl/hs-tour-setup to fix this error`;
    Sentry.captureMessage(msg);
    throw new Error(msg);
  } else {
    throw new Error(
      `There was an error completing the tour "${tour}", ${body.error}`,
    );
  }
}

/**
 * Returns the value of the shouldTargetAccount defined in the HelloFax repo.
 */
export async function isEligibleForTour(tour: TourName): Promise<boolean> {
  const res = await hsFetch(`/endpoint/account/verifyTour?tour=${tour}`, {
    method: 'POST',
  });
  const body = await res.json();

  if (res.status === 501) {
    const msg = `Cannot verify tour. Tour "${tour}" is not setup to work with the TourManager, see drl/hs-tour-setup to fix this error`;
    Sentry.captureMessage(msg);
    throw new Error(msg);
  }

  if (res.status !== 200) {
    throw new Error(
      `There was an error verifying the tour "${tour}", ${body.error}`,
    );
  }

  return body.data.eligible || false;
}

/**
 * Marks a tour as completed in both local storage and backend.
 *
 * @param tour
 * @param ops.disableBackend
 *
 * @returns
 */
export const completeTour = async (
  tour: TourName,
  ops: { disableBackend: boolean },
) => {
  if (!ops.disableBackend) {
    markTourAsCompletedOnBackend(tour);
  }

  mergeTourData({
    [tour]: {
      completed: true,
    },
  });

  return {
    success: true,
  };
};
