import React from 'react';
import {
  FieldValue,
  SavedData,
  SignatureRequest,
} from 'signer-app/signer-signature-document/types';
import { Field, FieldTypes } from 'signer-app/types/editor-types';
import {
  SignerAppClient,
  useSignerAppClient,
} from 'signer-app/context/signer-app-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UnreachableError } from 'signer-app/utils/unreachable';

type SignatureRequestResponse = Awaited<
  ReturnType<SignerAppClient['signerApi']['getSignatureRequest']>
>;

export const extractSavedData = (field: Field): FieldValue<Field> => {
  switch (field.type) {
    case FieldTypes.Hyperlink:
    case FieldTypes.Rectangle:
    case FieldTypes.Image:
      return {
        id: field.id,
        type: field.type,
        image: null,
      };
    case FieldTypes.Date:
    case FieldTypes.Dropdown:
      return {
        id: field.id,
        type: field.type,
        value: field.value,
      };
    case FieldTypes.Checkbox:
    case FieldTypes.Radio:
      return {
        id: field.id,
        type: field.type,
        checked: field.checked,
      };
    case FieldTypes.Initials:
    case FieldTypes.Signature:
      return {
        id: field.id,
        type: field.type,
        signature: field.signature,
      };
    case FieldTypes.Text:
      return {
        id: field.id,
        type: field.type,
        value: field.value,
        lines: field.lines,
        fontSize: field.fontSize,
      };
    // istanbul ignore next
    default:
      throw new UnreachableError(field);
  }
};

export const signExperienceKeys = {
  signerExperience: (signatureRequestId: string) =>
    ['signer-experience', signatureRequestId] as const,
};

type AutoSaveReturn = {
  error: unknown;
  isFetching: boolean;
  data: SignatureRequestResponse | undefined;
  /**
   * This is the working copy of the signature request. It is optimistically
   * updated through `onFieldUpdate`
   */
  signatureRequest: SignatureRequest | undefined;
  onFieldUpdate?: (field: Field, updates: Partial<Field>) => void;
};

export const SAVE_DELAY = NODE_ENV === 'test' ? 1 : 2000;
export function useAutoSaveSignerExperience(
  signatureRequestId: string,
): AutoSaveReturn {
  const client = useSignerAppClient();
  const queryClient = useQueryClient();

  const { error, data, isFetching } = useQuery({
    queryKey: signExperienceKeys.signerExperience(signatureRequestId),
    queryFn: () => {
      return client.signerApi.getSignatureRequest(signatureRequestId);
    },
  });
  const signatureRequest = data?.signatureRequest;

  const mutationKey = signExperienceKeys.signerExperience(signatureRequestId);
  const { mutate } = useMutation<unknown, unknown, SavedData>({
    mutationKey,
    mutationFn: async (updates) => {
      await queryClient.cancelQueries(mutationKey);
      const data =
        queryClient.getQueryData<SignatureRequestResponse>(mutationKey);
      if (data) {
        const fields = data.signatureRequest.fields.map((field) => {
          const saved = updates[field.id];
          if (saved && saved.type === field.type && saved.id === field.id) {
            // `saved` is a subset of `field`, but I don't see any way to get
            // TypeScript to understand that if field is a checkbox, then saved is
            // ALSO a checkbox. So to limit the type casting, the function is
            // annotated and only this line is cast.
            return { ...field, ...saved } as any;
          }
          return field;
        });
        queryClient.setQueryData(mutationKey, {
          ...data,
          signatureRequest: {
            ...data.signatureRequest,
            fields,
          },
        });
      }

      await client.signerApi.saveSignatureRequest(signatureRequestId, updates);
    },
  });

  const [draft, setDraft] = React.useState<SavedData | null>(null);
  React.useEffect(() => {
    if (draft) {
      const timeout = setTimeout(() => {
        mutate(draft);
        setDraft(null);
      }, SAVE_DELAY);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [draft, mutate]);

  const onFieldUpdate = React.useCallback(
    <T extends Field>(field: T, updates: Partial<T>): void => {
      const updatedField: Field = { ...field, ...updates };
      setDraft((prev): SavedData => {
        return {
          ...prev,
          [field.id]: extractSavedData(updatedField),
        };
      });
    },
    [],
  );

  const fields = React.useMemo((): SignatureRequest['fields'] => {
    const baseFields = signatureRequest?.fields;
    if (draft && baseFields) {
      return baseFields.map((field): Field => {
        const saved = draft[field.id];
        if (saved && saved.type === field.type && saved.id === field.id) {
          // `saved` is a subset of `field`, but I don't see any way to get
          // TypeScript to understand that if field is a checkbox, then saved is
          // ALSO a checkbox. So to limit the type casting, the function is
          // annotated and only this line is cast.
          return { ...field, ...saved } as any;
        }
        return field;
      });
    }

    return baseFields ?? [];
  }, [draft, signatureRequest?.fields]);

  return {
    onFieldUpdate,
    error,
    isFetching,
    data,
    signatureRequest:
      signatureRequest == null
        ? undefined
        : ({
            ...signatureRequest,
            fields,
          } as typeof signatureRequest),
  };
}
