import { createSimpleDataContext } from 'signer-app/utils/create-simple-data-context';

import { ButtonProps } from '@dropbox/dig-components/buttons';
import { BannerProps } from '@dropbox/dig-components/banner';
import { defineMessages } from 'react-intl';

export const notificationMessages = defineMessages({
  freeTrialExpiring: {
    id: '',
    description: 'notification, free trial expiring',
    defaultMessage:
      'Your free trial with Sign is going to expire in {days} day(s)!',
  },
  freeSubExpiring: {
    id: '',
    description: 'notification, free sub expiring',
    defaultMessage: 'Your free trial subscription is going to expire soon.',
  },
  alreadyLoggedIn: {
    id: '',
    description: 'text informing user that user is already logged in',
    defaultMessage: 'Looks like you’re already logged in with {account_email}.',
  },
  finishAcceptingInvitation: {
    id: '',
    description:
      'text in popup, asking user to login to respond the team invitation',
    defaultMessage: 'Please log in to finish accepting this team invitation.',
  },
  confirmAccountBeforeActivatingIntegrations: {
    id: '',
    description:
      'error message in popup, asking user to confirm account before activating integrations with third party applications',
    defaultMessage:
      'You must confirm that you own {account_email} before you activate integrations.',
  },
  confirmAccountBeforeTakingAction: {
    id: '',
    description:
      'error message in popup asking user to confirm account before taking action',
    defaultMessage:
      'You must confirm that you own {account_email} before you can perform this action.',
  },
  confirmAccountBeforeSigning: {
    id: '',
    description: 'notification header, asking user to confirm email address',
    defaultMessage:
      'You must confirm that you own {account_email} before signing a document.',
  },
  clickTheActivateLink: {
    id: '',
    description:
      'text in popup message asking user to click the activate link that was sent in email',
    defaultMessage:
      'Click the Activate link in the confirmation sent to your new email address',
  },
  contactSupportChangingEmailAddressFailed: {
    id: '',
    description:
      'error message in popup, asking user to contact support as changing email address failed',
    defaultMessage:
      "Can't change email address. Please try again or {openLink}contact us{closeLink}",
  },
  setUpGoogleAppsIntegration: {
    id: '',
    description:
      'pop-up, header, error, integrations, Google Apps integration, informs user that before using this integration user needs to set it up',
    defaultMessage: 'Your team needs to be configured.',
  },
  unableToPurchasePlan: {
    id: '',
    description:
      'notification header, informing that users are unable to purchase a plan that is on a different billing scheme',
    defaultMessage:
      "To add a plan, you'll need to adjust your current plan. Please {openATag}contact us{closeATag}.",
  },
  accountDeleted: {
    id: '',
    description:
      'notification header, informing the user that the account has been deleted',
    defaultMessage:
      'You deleted your Sign account. If this was a mistake, you can {openLink}sign up again{closeLink}.',
  },
  sendingLimitReached: {
    id: '',
    description:
      'error, popup, title, informs user that sending limit has been reached and redirects with link',
    defaultMessage:
      "Due to account security, you can't send any more signature requests until tomorrow. For assistance today, please {openLink}contact us{closeLink}",
  },
});

export type NotificationID = string;
export type NotificationBannerDataShape = {
  type?: BannerProps['type']; // default "attention"
  animate?: boolean; // default true
  withCloseButton?: boolean; // default true
  onRequestClose?: () => void;
  heapId?: string; // if defined, displayed event sent to heap
  autoClose?: boolean; // default true
  delay?: number; // default 7500
  actions?: ButtonProps[];
  withLeftIcon?: BannerProps['withLeftIcon'];

  message?: React.ReactNode | string;
  html?: string;
  msg?: {
    messageId: keyof typeof notificationMessages;
    values?: Record<string, string | number>;
  };
};

export type NotificationsShape = [
  NotificationID,
  NotificationBannerDataShape,
][];
export type NotificationBannerContextShape = {
  notifications: NotificationsShape;
  sendNotification: (data: NotificationBannerDataShape) => NotificationID;
  clearNotification: (id: NotificationID) => void;
  clearNotifications: () => void;
};

export const NotificationBannerContext =
  createSimpleDataContext<NotificationBannerContextShape>(
    'notificationBanners',
  );

export const useNotificationBanners = NotificationBannerContext.useContext;
