import styles from 'signer-app/signature-modal/type/type.module.css';

import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  IntlShape,
  useIntl,
} from 'react-intl';

import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { SyncingLine } from '@dropbox/dig-icons/assets';
import fonts from 'signer-app/signature-modal/constants/fonts';
import * as signatureTypes from 'signer-app/signature-modal/constants/signature-types';
import { useSignatureModalContext } from 'signer-app/signature-modal/contexts/signature-modal/context';
import type { SignatureModalContextShape } from 'signer-app/signature-modal/contexts/signature-modal/types';
import {
  changeFont,
  nextFont,
} from 'signer-app/signature-modal/contexts/signature-modal/actions';
import { SignatureModalLine } from 'signer-app/signature-modal/common/signature-modal-line';
import { AriaLiveProp, useAriaLive } from 'signer-app/utils/use-aria-live';

export interface TypeProps extends AriaLiveProp {
  intl: IntlShape;
  signatureModalContext: SignatureModalContextShape;
  enableInsertButtonCallback: (enableInsert: boolean) => void;
  initialTypeInValue: string;
  changeFont?: (fontIndex: number) => any;
  nextFont?: () => any;
}

const messages = defineMessages({
  typeInputAriaLabel: {
    id: '',
    description:
      'Screen reader label for an input field where the user types in their name.',
    defaultMessage: 'Enter your signature',
  },
  typeInputPlaceholder: {
    id: '',
    description:
      'Placeholder text for an input field when the user types in their name.',
    defaultMessage: 'Your name',
  },
  fontChangedAnnouncement: {
    id: '',
    description:
      'Screen reader announcement when font is changed. The font change is applied on a signature that user types in.',
    defaultMessage: 'Font changed to {newFontName}',
  },
});

class Type extends React.PureComponent<TypeProps> {
  inputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    const { state, dispatch } = this.props.signatureModalContext;

    // Check if we have loaded any fonts yet. If not, let's
    // load first one
    if (state.currentFontIndex === null) {
      const changeFontFunc = this.props.changeFont ?? changeFont;
      dispatch(changeFontFunc(0));
    }

    this.props.enableInsertButtonCallback(this.shouldEnableInsert());
  }

  onInputChange = () => {
    this.props.enableInsertButtonCallback(this.shouldEnableInsert());
  };

  onChangeFontPressed = () => {
    const { state, dispatch } = this.props.signatureModalContext;

    if (!state.isLoadingFont) {
      const nextFontFunc = this.props.nextFont ?? nextFont;
      dispatch(nextFontFunc());
    }
  };

  componentDidUpdate(prevProps: TypeProps) {
    const { signatureModalContext: prevContet } = prevProps;
    const { signatureModalContext: newContext, announce } = this.props;
    const { state: prevState } = prevContet;
    const { state: newState } = newContext;
    if (
      prevState.isLoadingFont === true &&
      newState.isLoadingFont === false &&
      // do not announce that font changed on initial font load
      prevState.currentFontIndex !== null &&
      newState.currentFontIndex !== null
    ) {
      announce(messages.fontChangedAnnouncement, {
        newFontName: fonts[newState.currentFontIndex],
      });
    }
  }

  getSignatureData() {
    const text = this.inputRef.current?.value.trim();

    return {
      create_type_code: signatureTypes.TYPED,
      signature: {
        text,
        font_family:
          fonts[this.props.signatureModalContext.state.currentFontIndex ?? 0],
      },
    };
  }

  shouldEnableInsert() {
    if (!this.inputRef.current) return false;

    return !!this.inputRef.current.value.trim().length;
  }

  isChineseLocale() {
    const intl = this.props.intl;
    const locale = intl ? intl.locale : null;
    return locale === 'zh-CN';
  }

  render() {
    const { intl, initialTypeInValue, signatureModalContext } = this.props;
    const { currentFontIndex, isLoadingFont } = signatureModalContext.state;

    return (
      <>
        <div className={styles.inputContainer}>
          <input
            ref={this.inputRef}
            onChange={this.onInputChange}
            className={styles.input}
            type="text"
            aria-label={intl.formatMessage(messages.typeInputAriaLabel)}
            placeholder={intl.formatMessage(messages.typeInputPlaceholder)}
            aria-required={true}
            defaultValue={initialTypeInValue}
            disabled={isLoadingFont}
            style={{ fontFamily: fonts[currentFontIndex ?? 0] }}
            data-qa-ref="signing-modal--type-in-field"
          />
          <SignatureModalLine />
        </div>
        {!this.isChineseLocale() ? (
          <div className={styles.buttons}>
            <Button
              size="small"
              variant="borderless"
              withIconLeft={<UIIcon src={SyncingLine} />}
              className={styles.changeFontButton}
              data-qa-ref="signing-modal--change-font"
              data-testid="signing-modal--change-font"
              onClick={this.onChangeFontPressed}
            >
              <FormattedMessage
                id=""
                description="Text for a button which changes the font of the typed-in signature when clicked."
                defaultMessage="Change font"
              />
            </Button>
          </div>
        ) : null}
      </>
    );
  }
}

export const TypeSignature = React.forwardRef(
  (
    props: Pick<TypeProps, 'enableInsertButtonCallback' | 'initialTypeInValue'>,
    ref: React.ForwardedRef<any>,
  ) => {
    const intl = useIntl();
    const announce = useAriaLive();
    const signatureModalContext = useSignatureModalContext();

    return (
      <Type
        {...props}
        intl={intl}
        ref={ref}
        signatureModalContext={signatureModalContext}
        announce={announce}
      />
    );
  },
);
