import { AutoFillType as AutofillFieldTypes } from 'js/sign-components/generated/types/HelloRequest';
import { defineMessages } from 'react-intl';
import { ValueOfArray } from 'signer-app/utils/value-of-array';

export const validationMessages = defineMessages({
  optionNumbersOnly: {
    id: '',
    description:
      'A field validation option name requiring that the user enter only numbers.',
    defaultMessage: 'Numbers only',
  },
  optionLettersOnly: {
    id: '',
    description:
      'A field validation option name requiring that the user enter only letters.',
    defaultMessage: 'Letters only',
  },
  optionPhoneNumber: {
    id: '',
    description:
      'A field validation option name requiring that the user enter only a valid phone number.',
    defaultMessage: '(USA) Phone number',
  },
  optionBankRoutingNumber: {
    id: '',
    description:
      'A field validation option name requiring that the user enter only a valid bank routing number.',
    defaultMessage: '(USA) Bank routing number',
  },
  optionBankAccountNumber: {
    id: '',
    description:
      'A field validation option name requiring that the user enter only a valid bank account number.',
    defaultMessage: '(USA) Bank account number',
  },
  optionEmailAddress: {
    id: '',
    description:
      'A field validation option name requiring that the user enter only a valid email address.',
    defaultMessage: 'Email address',
  },
  optionZipCode: {
    id: '',
    description:
      'A field validation option name requiring that the user enter only a valid zip code.',
    defaultMessage: '(USA) Zip code',
  },
  optionSSN: {
    id: '',
    description:
      'A field validation option name requiring that the user enter only a valid social security number.',
    defaultMessage: '(USA) Social security number',
  },
  optionEIN: {
    id: '',
    description:
      'A field validation option name requiring that the user enter only a valid employer identification number.',
    defaultMessage: '(USA) Employer identification number',
  },
  optionCustomRegex: {
    id: '',
    description:
      "A field validation option name which validates the user's input using a custom regular expression.",
    defaultMessage: 'Custom regex',
  },
  detailPhoneNumber: {
    id: '',
    description:
      'The description of a field validation option which requires the to user enter a valid phone number.',
    defaultMessage: '10 digits will be required',
  },
  detailBankRoutingNumber: {
    id: '',
    description:
      'The description of a field validation option which requires the to user enter a bank routing number.',
    defaultMessage: '9 digits will be required',
  },
  detailBankAccountNumber: {
    id: '',
    description:
      'The description of a field validation option which requires the to user enter a bank account number.',
    defaultMessage: 'Minimum of 6 digits will be required',
  },
  detailZipCode: {
    id: '',
    description:
      'The description of a field validation option which requires the to user enter a valid zip ode.',
    defaultMessage: '5 or 9 digits will be required',
  },
  detailSSN: {
    id: '',
    description:
      'The description of a field validation option which requires the to user enter a valid social security number.',
    defaultMessage: '9 digits will be required',
  },
  detailEIN: {
    id: '',
    description:
      'The description of a field validation option which requires the to user enter a valid employer identification number.',
    defaultMessage: '9 digits will be required',
  },
  detailCustomRegex: {
    id: '',
    description:
      "The description of a field validation option which validates the user's input using a custom regular expression.",
    defaultMessage: 'Specify a regular expression',
  },
});

export const validationMessageMap = {
  numbers_only: validationMessages.optionNumbersOnly,
  letters_only: validationMessages.optionLettersOnly,
  phone_number: validationMessages.optionPhoneNumber,
  bank_routing_number: validationMessages.optionBankRoutingNumber,
  bank_account_number: validationMessages.optionBankAccountNumber,
  email_address: validationMessages.optionEmailAddress,
  zip_code: validationMessages.optionZipCode,
  social_security_number: validationMessages.optionSSN,
  employer_identification_number: validationMessages.optionEIN,
  custom_regex: validationMessages.optionCustomRegex,
};

export const signatureColors = <const>['black', 'blue', 'red'];

export const fontColors = <const>['black', 'blue', 'red', 'white'];

export const fontFamilies = [
  'helvetica',
  'arial',
  'calibri',
  'cambria',
  'courier',
  'georgia',
  'times',
  'trebuchet',
  'verdana',
  'notoSerif',
  'notoSans',
  'roboto',
  'robotoMono',
] as const;
export type SignatureColor = ValueOfArray<typeof signatureColors>;
export type FontColor = ValueOfArray<typeof fontColors>;

export type FontFamily = (typeof fontFamilies)[number];

export { AutofillFieldTypes };

export enum FieldTypes {
  Checkbox = 'checkbox',
  Date = 'date',
  Initials = 'initials',
  Signature = 'signature',
  Text = 'text',
  Dropdown = 'dropdown',
  Radio = 'radiobutton',
  Hyperlink = 'hyperlink',
  Image = 'image',
  Rectangle = 'rectangle',
}

export function getAutoFillFieldType(type?: string): AutofillFieldTypes | null {
  switch (type) {
    case undefined:
    case null:
      return null;
    case AutofillFieldTypes.FullName:
      return AutofillFieldTypes.FullName;
    case AutofillFieldTypes.LastName:
      return AutofillFieldTypes.LastName;
    case AutofillFieldTypes.FirstName:
      return AutofillFieldTypes.FirstName;
    case AutofillFieldTypes.Company:
      return AutofillFieldTypes.Company;
    case AutofillFieldTypes.Email:
      return AutofillFieldTypes.Email;
    case AutofillFieldTypes.Title:
      return AutofillFieldTypes.Title;
    default:
      throw new Error(`Invalid auto fill type: ${type}`);
  }
}

export type SignatureData = {
  guid: string;
};

export interface BaseField {
  id: string;
  // API ID must be validated in realtime. It can't wait until the user presses
  // "Continue" to be fixed because we rely on using the ID as the key for
  // updating fields. If we did update the ID and then wait until the end to
  // validate, it would be impossible to fix because `updateFields({ [oldID]:
  // {id: newID}})` will update BOTH fields.
  invalidApiId?: string;
  pageIndex: number;
  name?: string;
  x: number;
  y: number;
  required?: boolean;
  // This is used for Conditional Logic. When a required field is hidden,
  // required is stored here as a backup in case the field is shown again.
  originalRequired?: boolean;
  signer: string;
  width: number;
  height: number;
  documentId: string;
  hidden?: boolean;
  readOnly?: boolean;
  // AutoFill
  autoFillType?: AutofillFieldTypes | null;
  linkId?: string | null;
  integrationMetadata?: FieldIntegrationMetadata | null;
  label?: string | null;
  formLabel?: string;
}

export type BaseFieldTypes = {
  type: FieldTypes;
};

export interface CheckboxField extends BaseField {
  type: FieldTypes.Checkbox;
  checked?: boolean | null;
  group?: string | null;
  requirement?: string | null;
  groupLabel?: string | null;
  isEditableMergeField?: boolean;
  groupFormLabel?: string;
}

export interface RadioField extends BaseField {
  type: FieldTypes.Radio;
  checked: boolean;
  // Radio buttons MUST be part of a group
  group: string;
  requirement: string | null;
  groupLabel?: string | null;
  groupFormLabel?: string;
}

export interface TextField extends BaseField {
  type: FieldTypes.Text;
  placeholder?: string;
  lineHeight?: number;
  fontFamily?: FontFamily;
  fontSize?: number;
  fontColor?: FontColor;
  fontWeight?: number;
  value?: string | null;
  validationType?: keyof typeof validationMessageMap;
  validationCustomRegexFormatLabel?: string;
  validationCustomRegex?: string;
  masked?: boolean;
  isEditableMergeField?: boolean;
  originalFontSize?: number;
  // `lines` is just `value` with newlines added for explicit word wrapping. We
  // need it because our current Cairo based overlay system doesn't contain a
  // text layout engine, so lines have to be pre-computed.
  lines?: string;
}

export interface DropdownField extends BaseField {
  type: FieldTypes.Dropdown;
  options: Array<string>;
  value?: string | null;
  fontFamily?: FontFamily;
  fontSize?: number;
}

export interface DateField extends BaseField {
  type: FieldTypes.Date;
  placeholder?: string;
  dateFormat?: string;
  fontFamily?: FontFamily;
  fontSize?: number;
  value?: string | null;
}

export interface SignatureField extends BaseField {
  type: FieldTypes.Signature;
  color?: (typeof signatureColors)[number];
  signature?: SignatureData;
}

export interface InitialsField extends BaseField {
  type: FieldTypes.Initials;
  color?: (typeof signatureColors)[number];
  signature?: SignatureData;
}

export interface HyperlinkField extends BaseField {
  type: FieldTypes.Hyperlink;
  lineHeight?: number;
  fontFamily?: FontFamily;
  fontSize?: number;
  value: string | null;
  url: string;
}

export interface RectangleField extends BaseField {
  type: FieldTypes.Rectangle;
  filled: boolean;
}
export interface ImageField extends BaseField {
  type: FieldTypes.Image;
  src: string;
  scale: boolean;
  align?: 'near' | 'middle' | 'far';
}

export type Field =
  | TextField
  | CheckboxField
  | DateField
  | SignatureField
  | InitialsField
  | DropdownField
  | RadioField
  | HyperlinkField
  | RectangleField
  | ImageField;

export type DocumentRotation = 0 | 1 | 2 | 3;
export type Document = {
  id: string;
  name: string;
  snapshotGuid: string;
  editable: boolean;
  pwRequired?: boolean;
  rootSnapshotGuid?: string;
  upload?: {
    progress: number;
    error?: string;
  };
};

export type Page = {
  src: string;
  width: number;
  height: number;
  documentId: string;
  orientation: 0 | 1;
  newOrientation?: 0 | 1;
  cdnSrc?: string | null;
};

export type SigFieldTypes = SignatureField | InitialsField;

export type Signer = {
  id: number | string;
  name: string;
  email: string | null;
  order?: number;
  deleted?: boolean;
  role?: string;
};

export type SignerOrder = {
  [signerId in Signer['id']]: number;
};

export type ValidationError = {
  id: string | null;
  property?: string;
  textId: string;
  values?: {
    name: string;
    signerOrder: number;
  };
};

export type MergeField = {
  name: string;
  type: FieldTypes.Text | FieldTypes.Checkbox;
  integrationMetadata?: FieldIntegrationMetadata & {
    isTopField?: boolean;
  };
};

export interface FieldOverrideProps {
  autoFillType?: string | null;
  signer?: string | null;
  isDisabled?: boolean;
  name?: string | null;
}

export type FieldIntegrationMetadata = {
  externalName?: string;
  externalType?: string;
};

export type Preparer = {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  company: string;
};

export type IntegrationContext = 'hubspot' | undefined;
