import React from 'react';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { getBrandName } from 'signer-app/utils/brand';
import { Button } from '@dropbox/dig-components/buttons';
import styles from 'signer-app/signer-signature-document/signed-data-modal.module.css';

const messages = defineMessages({
  close: {
    id: '',
    description: 'close modal',
    defaultMessage: 'Close',
  },
});

type Props = {
  onConfirm: () => void;
};

const SignedDataModal: React.FunctionComponent<Props> = ({ onConfirm }) => {
  const [visible, setVisible] = React.useState(true);
  const handleClose = React.useCallback(() => {
    setVisible(false);
  }, []);
  const handleConfirm = React.useCallback(() => {
    handleClose();
    onConfirm();
  }, [handleClose, onConfirm]);
  const intl = useIntl();

  return (
    <Modal
      open={visible}
      isCentered
      width="standard"
      withCloseButton={intl.formatMessage(messages.close)}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      aria-labelledby="signed-data-modal-title"
    >
      <Modal.Header hasBottomSpacing="title-standard">
        <Modal.Title id="signed-data-modal-title">
          <FormattedMessage
            id=""
            description="Automatically prefill fields? header"
            defaultMessage="Automatically prefill fields?"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text tagName="p" className={styles.text}>
          <FormattedMessage
            id=""
            description="Prefill fields disclaimer"
            defaultMessage="To save time, you can prefill fields with what you entered the last time you signed."
          />
        </Text>
        <Text tagName="p" className={styles.text}>
          <FormattedMessage
            id=""
            description="Prefill fields disclaimer"
            defaultMessage="After fields are prefilled, please review and update them if needed. {brandName} isn’t responsible for any data errors in prefilled fields."
            values={{ brandName: getBrandName('S') }}
          />
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={handleClose}>
          <FormattedMessage
            id=""
            description="Cancel button"
            defaultMessage="Cancel"
          />
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          <FormattedMessage
            id=""
            description="Prefill fields button"
            defaultMessage="Prefill fields"
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(SignedDataModal);
