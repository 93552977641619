import React from 'react';
import { Button } from '@dropbox/dig-components/buttons';
import {
  useSignatureRequestContext,
  useFieldsContext,
} from 'signer-app/signature-request/context';
import {
  useSignerAppParams,
  useSignerContext,
} from 'signer-app/signer-experience/signer-experience';
import { FormattedMessage } from 'react-intl';
import styles from 'signer-app/signer-experience/next-button.module.css';

export const NextButton: React.FC<object> = () => {
  const { selectedFieldIds } = useSignatureRequestContext();
  const { step } = useSignerAppParams();
  const { selectField } = useSignerContext();
  const [activeField] = selectedFieldIds;
  const fields = useFieldsContext();
  const handleNext = () => {
    let field;

    if (!activeField) {
      field = fields[0];
    } else {
      const i = fields.findIndex((f) => f.id === activeField);
      field = fields[(i + 1) % fields.length];
    }
    selectField(field.id);
  };

  return (
    <Button
      className={styles.nextButton}
      variant="primary"
      onClick={handleNext}
    >
      {step === 'preview' && (
        <FormattedMessage
          id="signer.preview.getStartedButton.labelText"
          defaultMessage="Get started"
          description="Button text in signer flow, when clicked goes to next page to fill input fields and sign"
        />
      )}
      {step === 'sign' && (
        <FormattedMessage
          id=""
          defaultMessage="Next"
          description="Next button in the signer experience."
        />
      )}
    </Button>
  );
};
