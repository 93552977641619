import React from 'react';
import styles from 'signer-app/signature-modal/saved/saved-signature.module.css';
import classnames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { DeleteLine } from '@dropbox/dig-icons/assets';
import { SignatureModalContext } from 'signer-app/signature-modal/signature-modal-context/context';
import { SigData } from 'signer-app/context/signer-app-client/signature';

const messages = defineMessages({
  signatureAriaLabel: {
    id: '',
    description: 'Screen reader label for the saved signature.',
    defaultMessage: 'Signature {index, number}',
  },
  deleteSignatureAriaLabel: {
    id: '',
    description:
      'Screen reader label for an icon button to delete a saved signature.',
    defaultMessage: 'Delete signature {index, number}',
  },
});

interface SavedSignatureProps {
  index: number;
  isSelected: boolean;
  signatureSrc: string;
  signature: SigData;
  onDelete: SignatureModalContext['onSavedSignatureRemove'];
}

const SavedSignature: React.FC<SavedSignatureProps> = ({
  index,
  isSelected,
  signatureSrc,
  signature,
  onDelete,
}) => {
  const intl = useIntl();

  const onDeleteMouseDown = (evt: React.MouseEvent) => {
    // prevent selecting a deleted signature
    evt.stopPropagation();
  };

  const onDeleteClick = (evt: React.MouseEvent) => {
    // prevent selecting a deleted signature
    evt.stopPropagation();
    onDelete(signature);
  };

  return (
    <div
      className={classnames(styles.savedSignature, {
        [styles.selected]: isSelected,
      })}
    >
      <div className={styles.savedSignatureInner}>
        <div
          id={`saved-signature-${index}`}
          role="img"
          aria-label={intl.formatMessage(messages.signatureAriaLabel, {
            index,
          })}
          className={styles.savedSignatureImage}
          style={{
            backgroundImage: `url(${signatureSrc})`,
          }}
        />
      </div>
      <IconButton
        variant="borderless"
        className={styles.savedSignatureDelete}
        aria-label={intl.formatMessage(messages.deleteSignatureAriaLabel, {
          index,
        })}
        onMouseDown={onDeleteMouseDown}
        onClick={onDeleteClick}
        data-qa-ref="signing-modal--delete"
      >
        <UIIcon src={DeleteLine} />
      </IconButton>
    </div>
  );
};

export default React.memo(SavedSignature);
