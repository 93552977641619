// This file needs tese 4 imports, but nothing in the namespaces folder should
// rely on any of these.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeContextCacher } from 'signer-app/utils/legacy-context-utilities';
import { appActionsPanel } from 'hello-react/components/app-actions-panel';
import {
  AppLogSnapshot,
  logAppActionsInDevelopment,
} from 'signer-app/utils/log-app-actions';
import {
  AccountApi,
  AccountApiKeyApi,
  TemplateApi,
  ApiAppApi,
  Middleware,
} from '@dropbox/sign-internal-client';
import * as fetchActions from 'hello-react/web-app-client/namespace/fetch';
import * as prepAndSend from 'hello-react/web-app-client/namespace/prep-and-send';
import * as templates from 'hello-react/web-app-client/namespace/templates';
import * as deepIntegration from 'hello-react/web-app-client/namespace/deep-integration';
import * as bulkSend from 'hello-react/web-app-client/namespace/bulk-send';
import * as admin from 'hello-react/web-app-client/namespace/admin';
import * as apidashboard from 'hello-react/web-app-client/namespace/apidashboard';
import * as editor from 'hello-react/web-app-client/namespace/editor';
import * as moar from 'hello-react/web-app-client/namespace/moar';
import * as sharepoint from 'hello-react/web-app-client/namespace/sharepoint';
import * as docToTemplate from 'hello-react/web-app-client/namespace/doc-to-template';
import * as gmail from 'hello-react/web-app-client/namespace/gmail';
import * as postActions from 'hello-react/web-app-client/namespace/post';
import * as docSummary from 'hello-react/web-app-client/namespace/document-summary';
import * as accountSettings from 'hello-react/web-app-client/namespace/account-settings';
import * as help from 'hello-react/web-app-client/namespace/help';
import * as billing from 'hello-react/web-app-client/namespace/billing-settings';
import * as integration from 'hello-react/web-app-client/namespace/integration';
import * as experiments from 'hello-react/web-app-client/namespace/experiments';
import * as payments from 'hello-react/web-app-client/namespace/payment';
import {
  getCSRFToken,
  needsCSRFToken,
  setCsrfToken,
} from 'js/sign-components/common/hs-fetch';
import { SignerAppClient } from 'signer-app/context/signer-app-client';
import {
  SignAppClientProvider,
  useSignAppClient,
} from 'js/sign-components/sign-app-client/context';
import { identity } from 'lodash';
import * as home from 'hello-react/web-app-client/namespace/home';
import * as global from 'hello-react/web-app-client/namespace/global';
import * as apiSettings from 'hello-react/web-app-client/namespace/api-settings';
import { buildSignerAppClient } from 'signer-app/context/signer-app-client/build-signer-app-client';

export { AppLogSnapshot };

export type WebAppClientNamespaces = SignerAppClient & {
  // Please use tours as an example for new namespaces.
  moar: typeof moar;
  prepAndSend: typeof prepAndSend;
  editor: typeof editor;
  bulkSend: typeof bulkSend;
  postActions: typeof postActions;
  deepIntegration: typeof deepIntegration;
  admin: typeof admin;
  apidashboard: typeof apidashboard;
  docSummary: typeof docSummary;
  docToTemplate: typeof docToTemplate;
  gmail: typeof gmail;
  fetchActions: typeof fetchActions;
  templates: typeof templates;
  sharepoint: typeof sharepoint;
  help: typeof help;
  billing: typeof billing;
  payments: typeof payments;
  accountSettings: typeof accountSettings;
  integration: typeof integration;
  experiments: typeof experiments;
  home: typeof home;
  global: typeof global;
  apiSettings: typeof apiSettings;
  accountApi: AccountApi;
  accountApiKeyApi: AccountApiKeyApi;
  templateApi: TemplateApi;
  apiAppApi: ApiAppApi;
};

/**
 * This function verifies that SignAppClient is a subset of
 * NamespacedAppActions.  This is important to maintain compatibility because
 * the same code needs to work when launched under the SignAppClient and
 * WebAppClient.
 */
export function verifyCompatibleClientTypes(
  webAppClient: WebAppClientNamespaces,
  signAppClient: SignerAppClient,
) {
  identity<SignerAppClient>(webAppClient);
  // @ts-expect-error
  identity<WebAppClientNamespaces>(signAppClient);
}

export const namespaceWarning = <T extends object>(
  namespace: string,
  t: T,
): T => {
  if (
    NODE_ENV === 'test' ||
    IS_STORYBOOK ||
    (NODE_ENV === 'development' && window.location.hostname.includes('dev-'))
  ) {
    // Grab the keys from a regular objecct
    let keys: Array<keyof T> = Object.keys(t) as Array<keyof T>;

    // But if the namespace has a prototype, assume it's a class and read its
    // methods. In an earlier version I only read the prototype if the keys were
    // empty, but that means if the class assigned any properties to the
    // instance, then there would be keys and it didn't pick up the methods.
    if (Object.getPrototypeOf(t) !== Object.getPrototypeOf({})) {
      keys = keys.concat(
        Object.getOwnPropertyNames(Object.getPrototypeOf(t)) as Array<keyof T>,
      );
    }

    keys = keys.filter((key) => !String(key).includes('___'));

    const entries = keys.map((key) => {
      const value = t[key];
      if (typeof value === 'function') {
        return [
          key,
          function deprecated() {
            throw new Error(`Call ${namespace}.${String(key)}() instead`);
          },
        ];
      }
      return [key, value];
    });

    return Object.fromEntries(entries) as T;
  }
  return t;
};

export const buildWebAppClient = (
  preloadedTsmGroupKey: string,
): WebAppClientNamespaces => {
  const hostname = new URL('/', window.location.href).host;
  const middleware: Middleware['pre'] = async (req) => {
    req.url = req.url.replace(
      'https://app.hellosign.com',
      `https://${hostname}`,
    );

    (req.init.headers as any).Accept = 'application/json';
    if (needsCSRFToken(req.url)) {
      // Adding global X-CSRF-Token
      (req.init.headers as any)['X-CSRF-Token'] = getCSRFToken();
    }

    // Add pre middleware
    return req;
  };

  const signAppClient = buildSignerAppClient(preloadedTsmGroupKey);
  const accountApi = new AccountApi().withPreMiddleware(middleware);
  const templateApi = new TemplateApi().withPreMiddleware(middleware);
  const accountApiKeyApi = new AccountApiKeyApi().withPreMiddleware(middleware);
  const apiAppApi = new ApiAppApi().withPreMiddleware(middleware);

  const actions: WebAppClientNamespaces = {
    ...signAppClient,
    // I left these in place at runtime for now to avoid the possibility of
    // causing a SEV. TypeScript doesn't know these exist at runtime and they're
    // not included when running tests. So if they're in use, it can only be in
    // untested JavaScript files.
    ...namespaceWarning('fetchActions', fetchActions),
    ...namespaceWarning('prepAndSend', prepAndSend),
    ...namespaceWarning('deepIntegration', deepIntegration),
    ...namespaceWarning('bulkSend', bulkSend),
    ...namespaceWarning('admin', admin),
    ...namespaceWarning('docToTemplate', docToTemplate),
    ...namespaceWarning('sharepoint', sharepoint),
    ...namespaceWarning('postActions', postActions),
    ...namespaceWarning('integration', integration),
    bulkSend,
    editor,
    fetchActions,
    accountApi,
    accountApiKeyApi,
    templateApi,
    moar,
    accountSettings,
    deepIntegration,
    docToTemplate,
    gmail,
    postActions,
    prepAndSend,
    sharepoint,
    integration,
    docSummary,
    admin,
    apidashboard,
    templates,
    help,
    billing,
    payments,
    experiments,
    home,
    global,
    apiSettings,
    apiAppApi,
  };

  return logAppActionsInDevelopment(actions);
};

interface AppContextProp {
  appContext: WebAppClientNamespaces;
}

export function withAppContext<P>(
  Component: React.ComponentType<P & AppContextProp & React.RefAttributes<{}>>,
) {
  // eslint-disable-next-line react/display-name
  return React.forwardRef((props: P, ref: React.Ref<any>) => {
    const appContext = useSignAppClient() as WebAppClientNamespaces;
    return <Component {...props} appContext={appContext} ref={ref} />;
  });
}

interface User {}

interface Props {
  csrfToken: string;
  csrfTokenAttachmentDelete: string;
  preloadedTsmGroupKey: string;
  initialUser?: User;
  appActions?: WebAppClientNamespaces;
}

interface State {
  // TODO: Remove this. It's leftover from before we started using Redux.
  user?: User;
}

export default class WebAppClient extends Component<Props, State> {
  static propTypes = {
    csrfToken: PropTypes.string.isRequired,
    csrfTokenAttachmentDelete: PropTypes.string,
    preloadedTsmGroupKey: PropTypes.string,
    initialUser: PropTypes.shape({
      primarySignatureGuid: PropTypes.string,
      settings: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
    }),
    children: PropTypes.node,

    termsURL: PropTypes.string,
    privacyURL: PropTypes.string,
  };

  state = {
    user: this.props.initialUser,
  };

  contextCache = makeContextCacher<WebAppClientNamespaces & { data: State }>();

  actions: WebAppClientNamespaces;

  constructor(props: Props) {
    super(props);
    setCsrfToken(props.csrfToken);
    this.actions =
      this.props.appActions ??
      buildWebAppClient(this.props.preloadedTsmGroupKey);
  }

  render() {
    return (
      <SignAppClientProvider client={this.actions}>
        {appActionsPanel}
        {this.props.children}
      </SignAppClientProvider>
    );
  }
}

/**
 * WebAppClient/AppActions - The client for src/hellospa
 * SignAppClient - The client for src/js
 *
 * SignAppClient is a subset of WebAppClient. This file is lying to TypeScript a
 * bit about the types, <AppContextProvider passes the existing
 * WebAppClient(AppActions) into the context. Because it's a SUPERset of SignAppClient, TypeScript
 * doesn't complain about providing the additional namespaces.
 *
 * If the code is calling `useWebAppClient`, then it was built under
 * <AppContextProvider, so this maintains backward compatibility.
 *
 * New code written in `src/js` should use `useSignAppClient` instead. That same hook
 * will provide the existing namespaces when rendered under <AppContextProvider.
 */
export function useWebAppClient() {
  const context = useSignAppClient() as WebAppClientNamespaces;
  return context;
}

export const useAppContext = useWebAppClient;
