import {
  InitialsField,
  SignatureField,
} from 'js/sign-components/generated/types/HelloRequest';
import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { SENDER, PREPARER } from 'signer-app/signature-request/constants';
import Image from 'signer-app/signature-request/display-field/image';

const messages = defineMessages({
  signature: {
    id: '',
    description: 'Alt text for an image of a signature.',
    defaultMessage: 'Signature',
  },
  signatureOfSenderOrPreparer: {
    id: '',
    description:
      'Alt text for an image of a signature from either the sender or preparer. Ex. "Signature of document preparer"',
    defaultMessage:
      'Signature of document {signer, select, preparer {preparer} sender {sender} other {signer}}',
  },
  signatureOfSigner: {
    id: '',
    description:
      'Alt text for an image of a signature from a signer with a specific index. Ex. "Signature of Signer 2"',
    defaultMessage: 'Signature of Signer {signer, number}',
  },
});

type Props = {
  fieldData: SignatureField | InitialsField;
  height: number;
  width: number;
  textScale: number;
  validationErrors: string[];
  getInstantValidationErrorHack: () => void;
  selectField: (fieldId: string) => void;
  updateFields: (fieldId: string, update: any) => void;
  isActive: boolean;
  documentPreview: boolean;
  runZoomIntoField: (fieldId: string) => void;
  isOverlay: boolean;
};

function Signature(
  {
    fieldData,
    height,
    width,
    textScale,
    validationErrors,
    getInstantValidationErrorHack,
    selectField,
    updateFields,
    isActive,
    documentPreview,
    runZoomIntoField,
    isOverlay,
    ...props
  }: Props,
  ref: React.ForwardedRef<unknown>,
) {
  const intl = useIntl();

  const altTextMessage = React.useMemo(() => {
    if ([PREPARER, SENDER].includes(fieldData.signer)) {
      return messages.signatureOfSenderOrPreparer;
    } else if (Number.isInteger(fieldData.signer)) {
      return messages.signatureOfSigner;
    } else {
      return messages.signature;
    }
  }, [fieldData]);

  if (fieldData.signature != null) {
    return (
      <Image
        alt={intl.formatMessage(altTextMessage, { signer: fieldData.signer })}
        id={fieldData.signature.guid}
        ref={ref}
        {...props}
      />
    );
  }

  return null;
}

export default React.forwardRef(Signature);
