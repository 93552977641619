import { defineMessages } from 'react-intl';
import { unreachable } from 'signer-app/utils/unreachable';

export enum AutofillFieldTypes {
  FullName = 'name',
  LastName = 'lastName',
  FirstName = 'firstName',
  Company = 'company',
  Email = 'email',
  EmailOrPhoneNumber = 'emailOrPhoneNumber',
  Title = 'title',
  None = 'none',
}

export const autofillFieldMessages = defineMessages({
  companyFieldLabel: {
    id: '',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Company" field.',
    defaultMessage: 'Company',
  },
  emailFieldLabel: {
    id: '',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Email" field.',
    defaultMessage: 'Email',
  },
  emailAddressFieldLabel: {
    id: '',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Email" field.',
    defaultMessage: 'Email address',
  },
  fullNameFieldLabel: {
    id: '',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Full name" field.',
    defaultMessage: 'Full name',
  },
  lastNameFieldLabel: {
    id: '',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Last name" field.',
    defaultMessage: 'Last name',
  },
  firstNameFieldLabel: {
    id: '',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "First name" field.',
    defaultMessage: 'First name',
  },
  titleFieldLabel: {
    id: '',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Job Title" field. This field describes a role or designation.',
    defaultMessage: 'Title',
  },
});

export function getAutofillFieldMessage(
  // The template literal allows us to use names that still get type-checked
  // without having to import the enum.
  type: AutofillFieldTypes | `${AutofillFieldTypes}`,
) {
  // This does type narrowing without having to create a string case (`case
  // 'name':`) for each item.
  const t = type as AutofillFieldTypes;

  switch (t) {
    /* eslint-disable max-len */
    case AutofillFieldTypes.FullName:
      return autofillFieldMessages.fullNameFieldLabel;
    case AutofillFieldTypes.FirstName:
      return autofillFieldMessages.firstNameFieldLabel;
    case AutofillFieldTypes.LastName:
      return autofillFieldMessages.lastNameFieldLabel;
    case AutofillFieldTypes.Company:
      return autofillFieldMessages.companyFieldLabel;
    case AutofillFieldTypes.Email:
      return autofillFieldMessages.emailAddressFieldLabel;
    case AutofillFieldTypes.Title:
      return autofillFieldMessages.titleFieldLabel;
    case AutofillFieldTypes.EmailOrPhoneNumber: // shouldn't be exposed on the frontend anyways
    case AutofillFieldTypes.None:
      return '';
    /* eslint-enable max-len */
    default:
      unreachable(t);
      return '';
  }
}
