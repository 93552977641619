import hsFetch from 'js/sign-components/common/hs-fetch';
import { assertDataSchemaResponse } from 'signer-app/utils/response-helpers';
import moment from 'moment';

import {
  APIUsageData,
  SignatureRequestStatusData,
  SignatureRequestUsageData,
  APICallbacksData,
  APIAccountData,
  APIEventTypesData,
  APIRequestsData,
  APICallbackData,
  APIRequestsHTTPStatusData,
  APIEndpointListData,
  APIRequestsErrorDistributionData,
  APISignatureRequestsData,
  APIIncidentsData,
  APISearchResultData,
  APIRequestsEndpointData,
  TeamAccountsData,
} from 'js/sign-components/generated/types/APIDashboard';

import {
  validateAPIAccountData,
  validateAPICallbacksData,
  validateAPIEventTypesData,
  validateAPIUsageData,
  validateSignatureRequestStatusData,
  validateSignatureRequestUsageData,
  validateAPICallbackData,
  validateAPIRequestsData,
  validateAPIEndpointListData,
  validateAPIRequestsHTTPStatusData,
  validateAPIIncidentsData,
  validateAPIRequestsErrorDistributionData,
  validateAPIRequestsEndpointData,
  validateTeamAccountsData,
} from 'js/sign-components/generated/validators/validateAPIDashboard';

export enum IntervalType {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
}

export enum StatusCode {
  Completed = 'Completed',
  Draft = 'Draft',
  Declined = 'Declined',
  Expired = 'Expired',
  Error = 'Error',
  OutForSignature = 'Out For Signature',
}

type ApiUsageArgs = {
  startDate: string;
  endDate: string;
  isTestMode: boolean;
  accountId: string;
};

type CallbacksArgs = {
  startDate: string;
  endDate: string;
  destination: string;
  selected: string[];
  page: number;
  isTestMode: boolean;
  query: string;
  accountId: string;
};

type CallbackArgs = {
  id: string;
  destination: string;
  isTestMode: boolean;
  accountId: string;
};

type ApiRequestsArgs = {
  startDate: string;
  endDate: string;
  selectedHttpStatus: string[];
  selectedMethod: string[];
  selectedEndpoints: string[];
  page: number;
  isTestMode: boolean;
  query: string;
  accountId: string;
};

type SignatureRequestStatusArgs = {
  startDate: string;
  endDate: string;
  selectedSignatureStatus: string[];
  page: number;
  isTestMode: boolean;
  query: string;
  accountId: string;
};

type GlobalSearchArgs = {
  isTestMode: boolean;
  accountId: string;
  query: string;
};

/**
 * Placeholder data type for legacy support.
 * TODO: when api dashboard detail page are re-design this data type should be set if possible
 */
export type LegacyDashboardData = any;

export async function apiUsage({
  startDate,
  endDate,
  isTestMode = false,
  accountId,
}: ApiUsageArgs): Promise<APIUsageData> {
  const url = '/apidashboard/signatureRequestUsage';
  const params = new URLSearchParams({
    start_date: moment(startDate).format(),
    end_date: moment(endDate).endOf('day').format(),
    test_mode: String(isTestMode),
    account_id: accountId,
  });
  const res = await hsFetch(`${url}?${params}`);

  return assertDataSchemaResponse(res, validateAPIUsageData);
}

type SignatureRequestStatusInsightArgs = {
  startDate: Date;
  endDate: Date;
  isTestMode: boolean;
  accountId: string;
};
export const signatureRequestStatus = async ({
  startDate,
  endDate,
  isTestMode,
  accountId,
}: SignatureRequestStatusInsightArgs): Promise<SignatureRequestStatusData> => {
  const qs = new URLSearchParams({
    start_date: moment(startDate).format(),
    end_date: moment(endDate).format(),
    test_mode: String(isTestMode),
    account_id: accountId,
  });
  const response = await hsFetch(`apidashboard/signatureRequestStatuses?${qs}`);
  return assertDataSchemaResponse(response, validateSignatureRequestStatusData);
};

export async function callbacks({
  startDate,
  endDate,
  destination,
  selected,
  page,
  isTestMode,
  query,
  accountId,
}: CallbacksArgs): Promise<APICallbacksData> {
  const url = '/apidashboard/events';
  const params = new URLSearchParams({
    destination,
    start_date: startDate,
    end_date: endDate,
    test_mode: String(isTestMode),
    page_num: String(page - 1),
    event_type: String(selected),
    query,
    account_id: accountId,
  });

  const res = await hsFetch(`${url}?${params}`);

  return assertDataSchemaResponse(res, validateAPICallbacksData);
}

export async function callback({
  id,
  destination,
  isTestMode,
  accountId,
}: CallbackArgs): Promise<APICallbackData> {
  const url = '/apidashboard/event';
  const params = new URLSearchParams({
    destination,
    id,
    test_mode: String(isTestMode),
    account_id: accountId,
  });
  const res = await hsFetch(`${url}?${params}`);

  return assertDataSchemaResponse(res, validateAPICallbackData);
}

export async function accountDetails(
  accountId: string,
): Promise<APIAccountData> {
  const params = new URLSearchParams({
    account_id: accountId,
  });
  const res = await hsFetch(`/apidashboard/accountInformation?${params}`);

  return assertDataSchemaResponse(res, validateAPIAccountData);
}

export async function eventTypes(): Promise<APIEventTypesData> {
  const res = await hsFetch('/apidashboard/eventTypesList');

  return assertDataSchemaResponse(res, validateAPIEventTypesData);
}

type APIRequestsHttpStatusArgs = {
  startDate: Date;
  endDate: Date;
  isTestMode: boolean;
  accountId: string;
};

export async function APIRequestsHttpStatus({
  startDate,
  endDate,
  isTestMode,
  accountId,
}: APIRequestsHttpStatusArgs): Promise<APIRequestsHTTPStatusData> {
  const params = new URLSearchParams({
    test_mode: String(isTestMode),
    start_date: moment(startDate).format(),
    end_date: moment(endDate).format(),
    account_id: accountId,
  });
  const res = await hsFetch(`apidashboard/apiRequestsHttpStatus?${params}`);
  return assertDataSchemaResponse(res, validateAPIRequestsHTTPStatusData);
}

export async function apiRequests({
  startDate,
  endDate,
  selectedHttpStatus,
  selectedMethod,
  selectedEndpoints,
  page,
  isTestMode,
  query,
  accountId,
}: ApiRequestsArgs): Promise<APIRequestsData | null> {
  const url = '/apidashboard/apiRequestsList';

  const params = new URLSearchParams({
    start_date: startDate,
    end_date: endDate,
    http_status: String(selectedHttpStatus),
    method: String(selectedMethod),
    endpoints: String(selectedEndpoints),
    page_num: String(page - 1),
    test_mode: String(isTestMode),
    query,
    account_id: accountId,
  });
  const res = await hsFetch(`${url}?${params}`);
  if (res.status >= 400 && res.status <= 599) {
    return null;
  }
  return assertDataSchemaResponse(res, validateAPIRequestsData);
}

export async function endpointList(): Promise<APIEndpointListData> {
  const res = await hsFetch('/apidashboard/endpointList');

  return assertDataSchemaResponse(res, validateAPIEndpointListData);
}

export async function signatureRequestStatusList(): Promise<APIEndpointListData> {
  const res = await hsFetch('/apidashboard/signatureRequestStatusList');

  return assertDataSchemaResponse(res, validateAPIEndpointListData);
}

/** This is for sending data to old api dashboard apiRequest and signatureRequest detail view.
 This contains so many endpoint and many conditional data with unknown types.
 So to avoid unnecessary data validation error we are not adding type and validation.
 TODO: When redesign detail view page, add proper data typing and assertDataSchemaResponse
 */
export async function apiRequest(
  id: string,
  accountId: string,
): Promise<LegacyDashboardData> {
  const parameters = new URLSearchParams({
    id,
    account_id: accountId,
  });
  const res = await hsFetch(`/apidashboard/apiRequestDetail?${parameters}`);
  return res.json();
}

type apiRequestsErrorDistributionArgs = {
  startDate: Date;
  endDate: Date;
  isTestMode: boolean;
  interval: string;
  accountId: string;
};

export async function apiRequestsErrorDistribution({
  startDate,
  endDate,
  isTestMode,
  interval,
  accountId,
}: apiRequestsErrorDistributionArgs): Promise<APIRequestsErrorDistributionData> {
  const parameters = new URLSearchParams({
    start_date: moment(startDate).format(),
    end_date: moment(endDate).format(),
    test_mode: String(isTestMode),
    interval,
    account_id: accountId,
  });
  const res = await hsFetch(
    `/apidashboard/apiRequestsErrorDistribution?${parameters}`,
  );
  return assertDataSchemaResponse(
    res,
    validateAPIRequestsErrorDistributionData,
  );
}

export async function signatureRequest(
  id: string,
  accountId: string,
): Promise<LegacyDashboardData> {
  const parameters = new URLSearchParams({
    id,
    account_id: accountId,
  });
  const res = await hsFetch(`/apidashboard/signatureRequest?${parameters}`);
  return res.json();
}

export async function signatureRequestList({
  startDate,
  endDate,
  selectedSignatureStatus,
  page,
  isTestMode,
  query,
  accountId,
}: SignatureRequestStatusArgs): Promise<APISignatureRequestsData | null> {
  const url = '/apidashboard/signatureRequestsList';
  const params = new URLSearchParams({
    start_date: startDate,
    end_date: endDate,
    status_filter: String(selectedSignatureStatus),
    page_num: String(page - 1),
    test_mode: String(isTestMode),
    query,
    account_id: accountId,
  });
  const res = await hsFetch(`${url}?${params}`);
  if (res.status !== 200) {
    return null;
  }

  return res.json();
}

export async function GlobalSearch({
  isTestMode,
  accountId,
  query,
}: GlobalSearchArgs): Promise<APISearchResultData | null> {
  const url = '/apidashboard/getSearchResults';
  const params = new URLSearchParams({
    test_mode: String(isTestMode),
    account_id: accountId,
    query,
  });
  const res = await hsFetch(`${url}?${params}`);
  if (res.status !== 200) {
    return null;
  }

  return res.json();
}

type SignatureRequestUsageArgs = {
  typeFlag: string;
  startDate: Date;
  endDate: Date;
  isTestMode: boolean;
  accountId: string;
};

export const signatureRequestUsage = async ({
  typeFlag,
  startDate,
  endDate,
  isTestMode,
  accountId,
}: SignatureRequestUsageArgs): Promise<SignatureRequestUsageData> => {
  const params = new URLSearchParams({
    type_flag: typeFlag,
    start_date: moment(startDate).format(),
    end_date: moment(endDate).format(),
    test_mode: String(isTestMode),
    account_id: accountId,
  });
  const url = 'apidashboard/signatureRequestUsageByType';
  const response = await hsFetch(`${url}?${params}`);
  return assertDataSchemaResponse(response, validateSignatureRequestUsageData);
};

export async function fetchIncidents(): Promise<APIIncidentsData> {
  const res = await hsFetch('/apidashboard/incidents');
  return assertDataSchemaResponse(res, validateAPIIncidentsData);
}

type ApiRequestsEndpointArgs = {
  startDate: Date;
  endDate: Date;
  isTestMode: boolean;
  accountId: string;
};

export async function apiRequestsEndpoint({
  startDate,
  endDate,
  isTestMode,
  accountId,
}: ApiRequestsEndpointArgs): Promise<APIRequestsEndpointData> {
  const params = new URLSearchParams({
    start_date: moment(startDate).format(),
    end_date: moment(endDate).format(),
    test_mode: String(isTestMode),
    account_id: accountId,
  });
  const res = await hsFetch(`/apidashboard/apiRequestsByEndpoints?${params}`);
  if (!res.ok) {
    const body = await res.json();
    throw new Error(body.error || '');
  }
  return assertDataSchemaResponse(res, validateAPIRequestsEndpointData);
}

export async function getTeamAccounts(): Promise<TeamAccountsData> {
  const res = await hsFetch('/apidashboard/getTeamAccounts');
  if (!res.ok) {
    const body = await res.json();
    throw new Error(body.error || '');
  }
  return assertDataSchemaResponse(res, validateTeamAccountsData);
}
